.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tilted {
	transform: perspective(1000px) rotateX(45deg) scaleY(2.2);
}

@font-face {
	font-family: "Baskville";
	font-style: normal;
	font-weight: normal;
	src: url(/public/assets/fonts/baskville.ttf) format("truetype");
}

@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-0.5deg); }
    20% { transform: translate(-2px, 0px) rotate(0.5deg); }
    30% { transform: translate(2px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(0.5deg); }
    50% { transform: translate(-1px, 2px) rotate(-0.5deg); }
    60% { transform: translate(-2px, 1px) rotate(0deg); }
    70% { transform: translate(2px, 1px) rotate(-0.5deg); }
    80% { transform: translate(-1px, -1px) rotate(0.5deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-0.5deg); }
}

p {
	font: 17pt Baskville;
	color: white;
}

.shakeImage {
    animation: shake 10s;
    animation-iteration-count: infinite;
}

.btnSubmit {
	width: 93%;
	height: 40px;
	margin-top: 20px;
	border: none;
	color: white;
	background-color: #977052;
}

.btnSubmit:hover {
	cursor: pointer;
}

#email {
	width: calc(80% - 61px);
	height: 40px;
	padding-left: 49px;
	font-size: 10pt;
}

.glowing-outline {
	width: 200px;
	height: 200px;
	background-color: #282c34;
	border-radius: 10px;
	box-shadow: 0 0 20px rgba(255, 255, 255, 0.8), 
				0 0 40px rgba(255, 255, 255, 0.6), 
				0 0 60px rgba(255, 255, 255, 0.4);
}

#formContainer {
	outline: 2px solid #977052;
	outline-width: thick;
	outline-style: double;
}

#main {
	background-image: url(/public/assets/boardroom.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 100%;
	z-index: 1;
	position: absolute;
}

#logo {
	z-index: 2;
	position: absolute;
	background-image: url(/public/assets/logo_big.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	filter: drop-shadow(10px 10px 2px rgba(0, 0, 0, 0.3));
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 5%;
	width: 90%;
	max-width: 1500px;
	height: 10%;
}

#songCaption {
	display: flex;
	background-color: rgba(50,50,50,0.8);	
}

#playerContainer {
	width: 300px;
	z-index: 2;
	position: absolute;
	bottom: 10px;
	left: 10px;	
}

#mainContent {
	line-height: 33px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-height: 780px) {
	#songCaption {
		display: none;
	}
}

@media only screen and (max-height: 600px) {
	#playerContainer {
		z-index: 1;
	}
}

@media only screen and (max-height: 510px) {
	form div p:first-child {
		margin: 0;
	}

	#mainContent {
		line-height: 25px;
	}
}

@media only screen and (max-height: 400px) {
	#logo {
		top: 10px;
	}
	p {
		font-size: 13pt;
	}
}